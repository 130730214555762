'use client';

import { $arenaDataError, $pageNotFoundStore, $pagesDataError } from 'app/app.model';
import { useUnit } from 'effector-react';

export const NotFoundInfo = () => {
    const arenaDataError = useUnit($arenaDataError);
    const pagesDataError = useUnit($pagesDataError);
    const notFound = useUnit($pageNotFoundStore);
    return (
        <div>
            {arenaDataError && <pre>ArenaDataError: {JSON.stringify(arenaDataError, null, 2)}</pre>}
            {pagesDataError && <pre>pagesDataError: {JSON.stringify(pagesDataError, null, 2)}</pre>}
            {notFound}
        </div>
    );
};
